<p-calendar
  [clearButtonStyleClass]="'sb-calendar-hide-clear'"
  [styleClass]="styleClass"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [inputStyleClass]="'aria-invalid:border-red-500'"
  panelStyleClass="{{ appendTo === 'body' ? 'sb-calendar-append-body' : '' }}"
  [(ngModel)]="dates"
  [ngModelOptions]="{ standalone: true }"
  [disabled]="disabled"
  [showButtonBar]="showButtonBar"
  [firstDayOfWeek]="1"
  [showClear]="false"
  [numberOfMonths]="numberOfMonths"
  [showWeek]="true"
  [showIcon]="true"
  [selectionMode]="'range'"
  [dateFormat]="dateFormat"
  [appendTo]="appendTo"
  [hideOnDateTimeSelect]="true"
  [inline]="inline"
  (ngModelChange)="onChangeInput(dates)"
  [ngModelOptions]="{ standalone: true }"
  (onClose)="onClose()"
></p-calendar>
