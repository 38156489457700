import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isValid, startOfDay } from 'date-fns';
import { Calendar, CalendarModule } from 'primeng/calendar';

import { getPrimeNGDateFormat } from '../../../../+authenticated/shared/locale/locale.helper';
import { format, parseDate } from '../../../date.helper';
import { DefaultDateFormat } from '../sb-calendar.model';

export const DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangePickerComponent),
  multi: true,
};

@Component({
  selector: 'date-range-picker',
  templateUrl: 'date-range-picker.component.html',
  providers: [DATE_RANGE_PICKER_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [CalendarModule, FormsModule],
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit {
  @Input()
  public appendTo: string;
  @Input()
  public showButtonBar = true;
  @Input()
  public inline = false;
  @Input()
  public disabled: boolean;
  @Input()
  public numberOfMonths = 2;
  @Input()
  public styleClass: string;

  private _minDate: Date;
  @Input()
  public set minDate(value) {
    if (!value) {
      this._minDate = null;
      return;
    }

    this._minDate = startOfDay(parseDate(value));
  }
  public get minDate(): Date {
    return this._minDate;
  }

  private _maxDate: Date;
  @Input()
  public set maxDate(value) {
    if (!value) {
      this._maxDate = null;
      return;
    }
    this._maxDate = startOfDay(parseDate(value));
  }
  public get maxDate() {
    return this._maxDate;
  }

  /**
   * TODO this shouldn't exist, as it is the default behavior of the primeng component.
   * But because I didn't want to change the behavior of existing date range pickers,
   * conditionally emitting changes (regardless of input validity) was the way to go.
   * Hopefully these components get replaced by our own soon in which we properly think about input/output
   */
  @Input()
  public alwaysEmitChanges = false;
  @ViewChild(Calendar)
  private calendar: Calendar;

  public dates: Date[];
  public onTouched: () => void;
  public onModelChange: (dates: string[]) => void;
  public dateFormat: string;
  private value: string[];

  public constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.dateFormat = getPrimeNGDateFormat(this.translateService.currentLang) ?? DefaultDateFormat;
  }

  // Change via typing
  public onChangeInput(dates: Date[]) {
    if (dates === null || (Array.isArray(dates) && !isValid(dates[0]) && !isValid(dates[1]))) {
      this.value = ['', ''];
      return;
    }
    this.value = dates.map((date) => (isValid(parseDate(date)) ? format(parseDate(date), 'yyyy-MM-dd') : ''));
    if (this.alwaysEmitChanges || dates.every((date) => isValid(date))) {
      this.calendar.hideOverlay();
      this.onChange();
    }
  }

  // called by the reactive form control
  public registerOnChange(fn: (dates: string[]) => void) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public writeValue(value: string[]) {
    if (!value || (Array.isArray(value) && !value[0] && !value[1])) {
      return;
    }

    this.value = value;
    if (Array.isArray(value)) {
      this.dates = value.map((value) => parseDate(value));
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange() {
    this.onModelChange(this.value);
  }

  public onClose() {
    // Check if end date is missing
    if (Array.isArray(this.value) && this.value[0] && !this.value[1]) {
      const singleDayPeriod = [this.value[0], this.value[0]];
      this.writeValue(singleDayPeriod);
      this.onChange();
    }
  }
}
